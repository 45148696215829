import React from "react"
import Layout from "../../components/layout-static"
import Seo from "../../components/seo"


const Page = () => {
  const metadata = {
    title: "Thank You",
    heroH1: "Thank You",
    heroH2: "We appreciate your business and we will be in touch shortly.",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>THANK YOU</h2>
            <p className="mb-8">
              Thank you for contacting Bartlett & Grippe. An attorney will
              review your information and contact you as soon as possible.
              Please be patient. If we are in court or with clients it may take
              longer than expected to get back to you. If the matter is urgent
              please call our office directly at{" "}
              <strong>
                <a href="tel:12034634939" class="bg-phone-number"><span class="bg-phone-number">203-463-4939</span></a>
              </strong>
            </p>
            <p className="mb-8">
              There is no obligation on your part and the consultation is free.
              We look forward to speaking with you.
            </p>
            <p>Sincerely,</p>
            <p>Bartlett & Grippe, LLC</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
